$black: #263045 !default; // Overload
$white: #fff !default; // Overload

$dark: $black !default; // Overload
$light: $white !default; // Overload

$black-bis: $dark !default;
$black-ter: $dark !default;
$grey-darker: $dark !default;
$grey-dark: #7e6d72;
$grey: #8798ad !default;
$grey-light: #b0bac9 !default;
$grey-lighter: #e9eeff !default;
$white-ter: #f7f7f7 !default;
$white-bis: #f5f7f9 !default;

$link: #5065ff !default;
$link-light: #dfe5ff !default;

$link-alpha10: rgba($link, 0.1) !default;
$link-alpha07: rgba($link, 0.07) !default;
$link-alpha03: rgba($link, 0.03) !default;

$primary: #5f8287 !default;
$primary-light: #bae6ec !default;

$success: #04932f !default;
$success-light: #a8ddb8 !default;

$info: #47a3d2 !default;
$info-light: #caedff !default;

$warning: #ffb319 !default;
$warning-light: #fff6e3 !default;

$danger: #ff4a4a !default;
$danger-light: #ffcbcb !default;

$border-color: $grey-lighter !default;

// Additional values

$icon-color-1: #ad8875 !default;
$icon-color-2: #6f627a !default;
$icon-color-3: #6f627a !default;
$icon-color-4: #ff8080 !default;
$icon-color-5: #774e94 !default;
$icon-color-6: #87655c !default;
$icon-color-7: #ffc155 !default;
$icon-color-8: #fa8c43 !default;
$icon-color-9: #53788d !default;
$icon-color-10: $danger !default;
$icon-color-11: $success !default;
$icon-color-12: $warning !default;
$icon-color-13: $link !default;
$icon-color-13: $info !default;
