// Add theme bullet-colors
@each $status, $bullet-color in $bullet-colors-custom {
    .#{$status} {
      background: $bullet-color;
  
      &.is-white {
        background-color: $progress-0;
      }
    }
    .text-#{$status} {
      color: $bullet-color;
    }
    .badge.is-#{$status} {
      background-color: rgba($bullet-color, 0.1);
      color: darken($bullet-color, 26%);
    }
  }
