/* You can add global styles to this file, and also import other style files */
/* insert custom variables here to override existing ones or create new ones */
@use "sass:list";
@use 'sass:math';

@import 'styles/variables/all';

@import '../node_modules/@case-app/angular-library/styles/main.scss';

/* Import custom style here */
@import 'styles/components/calendar';
@import 'styles/components/general';
@import 'styles/components/lists';
@import 'styles/components/nav';
@import 'styles/components/progress-bar';
