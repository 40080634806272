//controls

$input-background-color: $white-bis !default;
$input-placeholder-color: rgba($black-ter, 0.5) !default;
$input-color: $black !default;
$input-arrow: $black-ter !default;
$input-icon-color: $black-ter !default;
$input-icon-active-color: $link !default;

$input-border-color: $grey-lighter !default;

$input-hover-color: $black-ter !default;
$input-hover-border-color: rgba($black-ter, 0.05) !default;

$input-focus-border-color: $link !default;
$input-focus-color: $link !default;

$input-disabled-color: $grey !default;
$input-disabled-background-color: $grey-lighter !default;
$input-disabled-border-color: $grey !default;
$input-disabled-placeholder-color: bulmaRgba(
  $input-disabled-color,
  0.3
) !default;

$label-color: $grey !default;

//Custom variables
$field-background: $white-bis !default;
$border: $grey-lighter !default;
