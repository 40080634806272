$bullet-colors-custom: (
  progress-1: #e81ee0,
  progress-2: #b21ee8,
  progress-3: #5c1ee8,
  progress-4: #2e5bff,
  progress-5: #1391ff,
  progress-6: #1eb1e8,
  progress-7: #1de8de,
  progress-8: #2effca,
  progress-9: #3cf51f,
);
